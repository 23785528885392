import React from 'react'
import { Field, ErrorMessage } from 'formik';

export default function Needs(props) {

    return (
      <div className={`my-12 ${props.lock ? "opacity-25":"opacity-100"}`}> 
        <h2 className="font-polysans-bulky text-xl my-4"><label htmlFor="details">J'explique</label></h2> 
        <div>
          <Field disabled={props.lock ? true : false} id="details" component="textarea" rows="7" name="details" placeholder="Decrivez nous en quelques lignes votre problématique/vos questions. Exemple : Je dois intégrer un nouveau CRM et hésite entre plusieurs solutions, j’aimerais un retour d’expérience pour m’aider à choisir."
          className=" md:w-2/3 w-10/12 p-2 font-bold rounded-lg border-2 border-purple-700 text-gray-600 bg-white hover:border-purple focus:outline-black" />
          <ErrorMessage name="details" render={msg => 
            <div className="my-4">
              <div className="border-l-4 rounded-lg border-yellow-400 bg-yellow-50 p-4 md:w-2/3 w-10/12">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">{msg}</p>
                  </div>
                </div>
              </div>
            </div>
          } />
        </div>
        <div className="my-4 pr-8">Ma problématique concerne le(s) domaine(s) suivants :</div>
        <ul className="flex flex-wrap leading-loose mt-4 md:w-2/3 w-10/12">
          <li>
            <Field disabled={props.lock ? true : false} type="checkbox" className="sr-only peer" name="tags" id="tag_it" value="IT/TECH" />
            <label className="inline-block mx-2 my-2 drop-shadow-md px-4 py-1 bg-white border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-50 peer-checked:ring-purple peer-checked:ring-2 peer-checked:border-transparent" htmlFor="tag_it">IT/TECH</label>
          </li>
          <li>
            <Field disabled={props.lock ? true : false} type="checkbox" className="sr-only peer" name="tags" id="tag_market" value="Marketing - Com - Dev co" />
            <label className="inline-block mx-2 my-2 drop-shadow-md px-4 py-1 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-purple peer-checked:ring-2 peer-checked:border-transparent" htmlFor="tag_market">Marketing - Com - Dev co</label>
          </li>
          <li>
            <Field disabled={props.lock ? true : false} type="checkbox" className="sr-only peer" name="tags" id="tag_dg" value="Direction Générale" />
            <label className="inline-block mx-2 my-2 drop-shadow-md px-4 py-1 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-purple peer-checked:ring-2 peer-checked:border-transparent" htmlFor="tag_dg">Direction Générale</label>
          </li>
          <li>
            <Field disabled={props.lock ? true : false} type="checkbox" className="sr-only peer" name="tags" id="tag_outils" value="Méthodes & outils" />
            <label className="inline-block mx-2 my-2 drop-shadow-md px-4 py-1 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-purple peer-checked:ring-2 peer-checked:border-transparent" htmlFor="tag_outils">Méthodes & outils</label>
          </li>
          <li>
            <Field disabled={props.lock ? true : false} type="checkbox" className="sr-only peer" name="tags" id="tag_drh" value="DRH" />
            <label className="inline-block mx-2 my-2 drop-shadow-md px-4 py-1 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-purple peer-checked:ring-2 peer-checked:border-transparent" htmlFor="tag_drh">DRH</label>
          </li>
          <li>
            <Field disabled={props.lock ? true : false} type="checkbox" className="sr-only peer" name="tags" id="tag_compta" value="Gestion compta" />
            <label className="inline-block mx-2 my-2 drop-shadow-md px-4 py-1 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-purple peer-checked:ring-2 peer-checked:border-transparent" htmlFor="tag_compta">Gestion compta</label>
          </li>
          <li>
            <Field disabled={props.lock ? true : false} type="checkbox" className="sr-only peer" name="tags" id="tag_rse" value="RSE & DD" />
            <label className="inline-block mx-2 my-2 drop-shadow-md px-4 py-1 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-purple peer-checked:ring-2 peer-checked:border-transparent" htmlFor="tag_rse">RSE & DD</label>
          </li>
          <li>
            <Field disabled={props.lock ? true : false} type="checkbox" className="sr-only peer" name="tags" id="tag_achat" value="Achat & commerce" />
            <label className="inline-block mx-2 my-2 drop-shadow-md px-4 py-1 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-purple peer-checked:ring-2 peer-checked:border-transparent" htmlFor="tag_achat">Achat & commerce</label>
          </li>
          <li>
            <Field disabled={props.lock ? true : false} type="checkbox" className="sr-only peer" name="tags" id="tag_droit" value="Droit" />
            <label className="inline-block mx-2 my-2 drop-shadow-md px-4 py-1 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-purple peer-checked:ring-2 peer-checked:border-transparent" htmlFor="tag_droit">Droit</label>
          </li>
          <li>
            <Field disabled={props.lock ? true : false} type="checkbox" className="sr-only peer" name="tags" id="tag_finance" value="Finance et fiscalité" />
            <label className="inline-block mx-2 my-2 drop-shadow-md px-4 py-1 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-purple peer-checked:ring-2 peer-checked:border-transparent" htmlFor="tag_finance">Finance et fiscalité</label>
          </li>
          <li>
            <Field disabled={props.lock ? true : false} type="checkbox" className="sr-only peer" name="tags" id="tag_autre" value="Autre..." />
            <label className="inline-block mx-2 my-2 drop-shadow-md px-4 py-1 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-purple peer-checked:ring-2 peer-checked:border-transparent" htmlFor="tag_autre">Autre...</label>
          </li>
        </ul>

        <ErrorMessage name="tags" render={msg => 
            <div className="my-4 mx-1">
              <div className="border-l-4 rounded-lg border-yellow-400 bg-yellow-50 p-4 md:w-2/3 w-10/12">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">{msg}</p>
                  </div>
                </div>
              </div>
            </div>
          } />

      </div>
    )
}