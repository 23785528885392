import React from "react";
import { Field } from 'formik';


class Terms extends React.Component {

  render() {
    return (
      <div className={`my-12 ${this.props.lock ? "opacity-25":"opacity-100"}`}> 
          <div className="my-2 md:w-2/3 w-10/12 flex">
              <Field disabled={this.props.lock ? true : false} type="checkbox" className="accent-purple" name="has_accept_terms" id="has_accept_terms" />
              <label className="pl-4" htmlFor="has_accept_terms">En cochant cette case, j'accepte que mes données soient traitées par Markopolo dans l'objectif de trouver un talent. J'accepte aussi que Markopolo me créé un compte pour le suivi de ma demande. Vos données ne seront ni échangées, ni revendues à des éventuels tiers.</label>
          </div>
      </div>
    )
  }
}

export default Terms