import React from 'react'

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.searchParams = new URLSearchParams(document.location.search)
    this.homeLink = 'https://markopolo.fr';
    if (this.searchParams.get('utm_campaign') === 'digital') {
      this.homeLink = 'https://markopolo.fr/' + this.searchParams.get('utm_campaign') + '/';
    } 
  }

  render(){
    return (
      <header className=" ">
          <nav className="sm:ml-48 ml-24">
              <h1 className="font-polysans-bulky text-3xl sm:my-16 my-12"><a id="top" name="top" 
                href={this.homeLink}
              >Markopolo</a>  </h1>
          </nav>
      </header>
    )
  }
}

export default Header;