import React from 'react'
import { Field, ErrorMessage } from 'formik';

export default function Introduction(props) {

    return (
      <div className={`my-12 ${props.lock? "opacity-25":"opacity-100"}`}> 
        <h2 className="font-polysans-bulky text-xl my-4">Je suis</h2> 
        <div className="my-2">
          <span className="inline-block mx-1">Je suis un(e)</span>
          <Field as="select" disabled={props.lock? true : false} name="status" className="mx-1 w-42 p-2 font-bold rounded-lg border-2 border-purple-700 text-gray-600 bg-white hover:border-purple focus:outline-black">
              <option value="">Préciser</option>
              <option value="Indépendant(e)">Indépendant(e)</option>
              <option value="Salarié(e)">Salarié(e)</option>
              <option value="Gérant(e)">Gérant(e)</option>
          </Field>

          <ErrorMessage name="status" render={msg => 
            <div className="my-4 mx-1">
              <div className="border-l-4 rounded-lg border-yellow-400 bg-yellow-50 p-4 md:w-2/3 w-10/12">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">{msg}</p>
                  </div>
                </div>
              </div>
            </div>
          } />

        </div>
        <div className="my-2">
          <span className="inline-block mx-1">J'évolue dans le secteur </span>
          <Field as="select" disabled={props.lock? true : false} name="sector" className="mx-1 w-56 p-2 font-bold rounded-lg border-2 border-purple-700 text-gray-600 bg-white hover:border-purpl focus:outline-black">
            <option value="">Préciser</option>
            <option value="Agroalimentaire">Agroalimentaire </option>
            <option value="Banque / Assurance">Banque / Assurance </option>
            <option value="Bois / Papier / Carton / Imprimerie">Bois / Papier / Carton / Imprimerie </option>
            <option value="BTP / Matériaux de construction">BTP / Matériaux de construction </option>
            <option value="Chimie / Parachimie">Chimie / Parachimie </option>
            <option value="Commerce / Négoce / Distribution">Commerce / Négoce / Distribution </option>
            <option value="Édition / Communication / Multimédia">Édition / Communication / Multimédia </option>
            <option value="Électronique / Électricité">Électronique / Électricité </option>
            <option value="Études et conseils">Études et conseils </option>
            <option value="Industrie pharmaceutique">Industrie pharmaceutique </option>
            <option value="Informatique / Télécoms">Informatique / Télécoms </option>
            <option value="Machines et équipements / Automobile">Machines et équipements / Automobile </option>
            <option value="Métallurgie / Travail du métal">Métallurgie / Travail du métal </option>
            <option value="Plastique / Caoutchouc">Plastique / Caoutchouc </option>
            <option value="Services aux entreprises">Services aux entreprises </option>
            <option value="Textile / Habillement / Chaussure">Textile / Habillement / Chaussure </option>
            <option value="Transports / Logistique">Transports / Logistique</option>
          </Field>

          <ErrorMessage name="sector" render={msg => 
            <div className="my-4 mx-1">
              <div className="border-l-4 rounded-lg border-yellow-400 bg-yellow-50 p-4 md:w-2/3 w-10/12">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">{msg}</p>
                  </div>
                </div>
              </div>
            </div>
          } />

        </div>
        <div className="my-2">
          <span className="inline-block mx-1">Mon entreprise est de </span>
          <Field as="select" disabled={props.lock? true : false} name="company_size" className="mx-1 w-28 p-2 font-bold rounded-lg border-2 border-purple-700 text-gray-600 bg-white hover:border-purple focus:outline-black">
            <option value="">Préciser</option>
            <option value="Petite">Petite</option>
            <option value="Moyenne">Moyenne</option>
            <option value="Grande">Grande</option>
          </Field> taille
          <ErrorMessage name="company_size" render={msg => 
            <div className="my-4 mx-1">
              <div className="border-l-4 rounded-lg border-yellow-400 bg-yellow-50 p-4 md:w-2/3 w-10/12">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">{msg}</p>
                  </div>
                </div>
              </div>
            </div>
          } />
        </div>
        <div className="my-2">
          <span className="inline-block mx-1">Et se trouve dans la région</span>
          <Field as="select" disabled={props.lock? true : false} name="state" className="mx-1 w-56 p-2 font-bold rounded-lg border-2 border-purple-700 text-gray-600 bg-white hover:border-purple focus:outline-black">
            <option value="">Préciser</option>
            <option value="Auvergne-Rhône-Alpes">Auvergne-Rhône-Alpes</option>
            <option value="Bourgogne-Franche-Comté">Bourgogne-Franche-Comté</option>
            <option value="Bretagne">Bretagne</option>
            <option value="Centre-Val de Loire">Centre-Val de Loire</option>
            <option value="Corse">Corse</option>
            <option value="Grand Est">Grand Est</option>
            <option value="Hauts-de-France">Hauts-de-France</option>
            <option value="Île-de-France">Île-de-France</option>
            <option value="Normandie">Normandie</option>
            <option value="Nouvelle-Aquitaine">Nouvelle-Aquitaine</option>
            <option value="Occitanie">Occitanie</option>
            <option value="Pays de la Loire">Pays de la Loire</option>
            <option value="Provence-Alpes-Côte d'Azur">Provence-Alpes-Côte d'Azur</option>
            <option value="Guadeloupe">Guadeloupe</option>
            <option value="Martinique">Martinique</option>
            <option value="Guyane">Guyane</option>
            <option value="La Réunion">La Réunion</option>
            <option value="Mayotte">Mayotte</option>
          </Field>
          <ErrorMessage name="state" render={msg => 
            <div className="my-4 mx-1">
              <div className="border-l-4 rounded-lg border-yellow-400 bg-yellow-50 p-4 md:w-2/3 w-10/12">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">{msg}</p>
                  </div>
                </div>
              </div>
            </div>
          } />
        </div>
      </div>
    )
    

}
