import React from 'react'

class Footer extends React.Component {
  render(){
    return (
      <footer className="text-center sm:py-16 py-12 text-gray-400">
        &copy;2024 Markopolo - <a href="https://gomarkopolo.com/terms.html">Mentions légales</a>
    </footer>
    )
  }
}

export default Footer;