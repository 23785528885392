import React from 'react'
import { Field } from 'formik';

export default function mission_type() {

  return (
    <div className={`my-12`}>  
      <h2 className="font-polysans-bulky text-xl my-4">Mon besoin</h2>
      <div className="my-4 pr-8">D'après vous, quel format serait le plus adapté pour votre consultation ?</div>
      <ul className="flex flex-wrap">
        <li>
          <Field type="radio" className="sr-only peer" name="mission_type" id="type_express" value="Express" />
          <label className="m-2 w-52 h-24 text-center inline-block grid place-content-center drop-shadow-md p-5 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-black hover:bg-gray-50 peer-checked:ring-purple peer-checked:ring-4 peer-checked:border-transparent" htmlFor="type_express">
          Express <span className="text-purple italic">Moins de 2h</span>
          </label>
        </li>
        <li>
          <Field type="radio" className="sr-only peer" name="mission_type" id="type_mission" value="Mission" />
          <label className="m-2 w-52 h-24 text-center inline-block grid place-content-center drop-shadow-md p-5 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-black hover:bg-gray-50 peer-checked:ring-purple peer-checked:ring-4 peer-checked:border-transparent" htmlFor="type_mission">
            Mission <span className="text-purple italic">Plusieurs échanges</span>
          </label> 
        </li>
        <li>
          <Field type="radio" className="sr-only peer" name="mission_type" id="type_none" value="None" />
          <label className="m-2 w-52 h-24 text-center inline-block grid place-content-center drop-shadow-md p-5 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-black hover:bg-gray-50 peer-checked:ring-purple peer-checked:ring-4 peer-checked:border-transparent" htmlFor="type_none">
          Je ne sais pas <span className="text-purple italic">Choisissez pour moi</span>
          </label>
        </li>
      </ul>
    </div>
  )

}