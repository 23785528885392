import React from 'react'
import { Field, ErrorMessage } from 'formik';

export default function ContactMethod(props) {
  
  return (
    <div className={`my-12 ${props.lock ? "opacity-25":"opacity-100"}`}> 
      <h2 className="font-polysans-bulky text-xl my-4">Me recontacter</h2> 
      <div className="my-2">
        <label htmlFor="" className="w-36 inline-block">Par email</label>
        <Field disabled={props.lock? true : false} type="text" className="w-48 p-2 font-bold rounded-lg border-2 border-purple-700 text-gray-600 bg-white hover:border-purple " name="email" id="email" placeholder="Email..." />
        <ErrorMessage name="email" render={msg => 
            <div className="my-4">
              <div className="border-l-4 rounded-lg border-yellow-400 bg-yellow-50 p-4 md:w-2/3 w-10/12">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">{msg}</p>
                  </div>
                </div>
              </div>
            </div>
          } />
      </div>
      <div className="my-2">
        <label htmlFor="" className="w-36 inline-block">Par téléphone</label>
        <Field disabled={props.lock? true : false} type="text" className="w-48 p-2 font-bold rounded-lg border-2 border-purple-700 text-gray-600 bg-white hover:border-purple " name="phone" id="phone" placeholder="06..." />
        <ErrorMessage name="phone" render={msg => 
            <div className="my-4">
              <div className="border-l-4 rounded-lg border-yellow-400 bg-yellow-50 p-4 md:w-2/3 w-10/12">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">{msg}</p>
                  </div>
                </div>
              </div>
            </div>
          } />
      </div>
      
    </div>
  )
}