import React from 'react'
import * as Yup from 'yup'
import Footer from '../../Components/Footer'
import Header from '../../Components/Header'
import Availability from '../../Components/Order/Availability'
import ContactMethod from '../../Components/Order/ContactMethod'
import Introduction from '../../Components/Order/Introduction'
import MissionType from '../../Components/Order/MissionType'
import Needs from '../../Components/Order/Needs'
import SpecialCode from '../../Components/Order/SpecialCode'
import Terms from '../../Components/Order/Terms'
import Stepper from '../../Components/Stepper'
import { Form, Formik } from 'formik'
import { createClient } from '@supabase/supabase-js'
import axios from 'axios';

class Order extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      step: 1,
      introduction: true,
      needs: true,
      availability: true,
      contactMethod: true,
      special_code: true,
      terms: true,
      submit: true,
      percent: 0,
      showSubmitButton: false,
      isLoading: false,
      error: false
    }
    this.formikRef = React.createRef();
    this.introductionRef = React.createRef();
    this.needsRef = React.createRef();
    this.availabilityRef = React.createRef();
    this.contactMethodRef = React.createRef();
    this.specialCodeRef = React.createRef();
    this.termsRef = React.createRef();
    this.alreadySeeAnimation = {
      introduction : false,
      needs : false,
      availability : false,
      contactMethod : false,
      special_code : false,
      terms : false
    }

    this.orderSchema = Yup.object().shape({

      status: Yup.string()
      .trim()
      .required('Ce champs est obligatoire.'),

      sector: Yup.string()
      .trim()
      .required('Ce champs est obligatoire.'),

      company_size: Yup.string()
      .trim()
      .required('Ce champs est obligatoire.'),

      state: Yup.string()
      .trim()
      .required('Ce champs est obligatoire.'),

      availability: Yup.string()
      .min(5, 'Il faut nous donner plus d\'information afin que nous puissions trouver un créneau commun.')
      .required('Il est important de nous donner vos disponibilités pour que nous puissions trouver un créneau commun.'),

      details: Yup.string()
      .min(20, 'Il faut nous donner plus détails pour que nous puissions vous trouver le bon talent.')
      .required('Il est important de nous donner des détails pour que nous puissions vous trouver le bon talent.'),

      tags: Yup.array()
      .min(1, 'Veuillez spécifier au moins un domaine lié à votre problématique.'),

      email: Yup.string()
      .email('L\'email mentionné semble mal formaté.')
      .required('Ce champs est obligatoire.'),
      
      phone: Yup.string() // https://regex101.com/r/dsi0Pw/2
      .matches(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/, {message: "Numéro de téléphone incorrect.", excludeEmptyString: false})
      .required('Ce champs est obligatoire.')

    });

    this.supabase = createClient(
      'https://wqibcekzgvnuplbjyryn.supabase.co', 
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndxaWJjZWt6Z3ZudXBsYmp5cnluIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njk1NTE0NDIsImV4cCI6MTk4NTEyNzQ0Mn0.adBcdKdbmcRJrNHPYb8N-x9zgqSBHZUW7I34ZIqogvA'
    )
  }

  scrollToElementNeeds = () => this.needsRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})

  calculatePercent (values) {
    const totalMandatoryFields = 10;
    const emptyMandatoryFields = Object.entries(values).join('#').split(',#').length - 4;
    let totalFillFields = totalMandatoryFields - emptyMandatoryFields;
    const percent = Math.round(totalFillFields * 100 / totalMandatoryFields);
    this.setState({percent: percent > 100 ? 100 : percent})
  } 

  validate = values => {

    this.calculatePercent(values)

    if (values.mission_type !== '') {
      if (!this.alreadySeeAnimation.introduction) { 
        this.setState({step: 2, introduction: false}, () => {
          this.introductionRef.current.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
          this.alreadySeeAnimation.introduction = true;
        });
      }
    }

    if (values.status !== '' && values.sector !== '' && values.company_size !== '' && values.state !== '') {
      if (!this.alreadySeeAnimation.needs) { 
        this.setState({step: 3, needs: false}, () => {
          this.needsRef.current.getElementsByTagName("textarea")[0].focus();
          this.needsRef.current.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
          this.alreadySeeAnimation.needs = true;
        });
        
      }
    }

    if (values.details !== '' && values.tags !== '') {
      if (!this.alreadySeeAnimation.availability) { 
        this.setState({step: 4, availability: false}, () => {
          this.availabilityRef.current.getElementsByTagName("textarea")[0].focus();
          this.availabilityRef.current.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
          this.alreadySeeAnimation.availability = true;
        });
      }
    }

    if (values.availability !== '') {
      if (!this.alreadySeeAnimation.contactMethod) { 
        this.setState({step: 5, contactMethod: false}, () => {
          this.contactMethodRef.current.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
          this.alreadySeeAnimation.contactMethod = true;
        })
      }
    }

    if (values.email !== '' || values.phone !== '') {
      if (!this.alreadySeeAnimation.special_code) { 
        this.setState({special_code: false, terms: false}, () => {
          this.alreadySeeAnimation.special_code = true;
        });
      }
    }

    if (values.has_accept_terms !== '') {
      if (!this.alreadySeeAnimation.terms) { 
        this.termsRef.current.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
        this.alreadySeeAnimation.terms = true;
      }
    }
    
    this.setState({showSubmitButton : (this.state.percent === 100 && values.has_accept_terms === true) ? true : false})

    return {}
  }

  render() {

    return (
      <div className="font-poppins"> 
        <Header />
        
        <main className="bg-gray-50">
          <div className="flex">
            <div className="">
              <Stepper step={this.state.step} percent={this.state.percent} isLoading={this.state.isLoading} />
            </div>
            <div className="sm:ml-48 ml-24">
              <div className="relative">

                <div className={`bg-orangelight p-3 rounded-lg my-8 md:w-2/3 w-10/12 ${this.state.error ? "block":"hidden"}`}>
                  <div className="bg-orange rounded-lg p-4">
                    <strong>Oups !</strong> nous avons rencontré un problème lors de l'enregistrement de votre demande. Vérifiez votre connexion, si le problème persiste, contactez nous <a href="mailto:contact@markopolo.fr">contact@markopolo.fr</a>
                  </div>
                </div>
              
                <div className={`${this.state.isLoading ? "blur-sm":""}`}>
                  <Formik
                    validationSchema={this.orderSchema}
                    validate={this.validate}
                    innerRef={this.formikRef}
                    initialValues={{
                      mission_type: '',
                      status: '',
                      sector: '',
                      company_size: '',
                      state: '',
                      details: '',
                      tags: '',
                      availability: '',
                      email: '',
                      phone: '',
                      special_code: '',
                      sponsor: '',
                      has_special_code: '',
                      has_accept_terms: ''
                    }}
                    onSubmit={async (values) => {
                      this.setState({isLoading: true});
                      //await new Promise((r) => setTimeout(r, 250));
                      const postData = { ...values };
                      // sponsor
                      const searchParams = new URLSearchParams(document.location.search);
                      postData.sponsor = searchParams.get('sponsor') ? searchParams.get('sponsor')  : '';
                      // clean 
                      delete postData.has_special_code;
                      delete postData.has_accept_terms;

                      const { errorSupa } = await this.supabase
                        .from('leads')
                        .insert(postData);

                      await axios.post(process.env.REACT_APP_HUB_API_URL + '/convert-lead-into-user', postData)
                      
                      if (!errorSupa) {
                        window.location.href = '/order/success'
                      } else {
                        this.setState({isLoading: false, error: true});
                        window.location.href = '/order#top'
                      }

                    }}
                  >
                    <Form>
                    <div className="mt-10"><div className="border-l-4 rounded-lg border-blue-400 bg-blue-50 p-4 md:w-2/3 w-10/12"><div className="flex"><div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clipRule="evenodd" />
      </svg></div><div className="ml-3">
                    <p className="text-md text-blue-400">Décrivez-nous votre question/problématique en quelques minutes en complétant ce court formulaire.
Un espace personnel sera créé sur Markopolo dès l’envoi de votre demande, dans lequel vous pourrez accéder à nos propositions de consultation.
Il vous suffit de cliquer sur le mail de confirmation du mail automatique que vous recevrez, on s’occupe du reste !.</p></div></div></div></div>
                      <div><MissionType /></div>
                      <div ref={this.introductionRef}><Introduction lock={this.state.introduction} /></div>
                      <div ref={this.needsRef}><Needs lock={this.state.needs} /></div>
                      <div ref={this.availabilityRef}><Availability lock={this.state.availability} /></div>
                      <div ref={this.contactMethodRef}><ContactMethod lock={this.state.contactMethod} /></div>
                      <div ref={this.specialCodeRef}><SpecialCode lock={this.state.special_code} /></div>
                      <div ref={this.termsRef}><Terms lock={this.state.terms} /></div>
                      <button 
                        disabled={!this.state.showSubmitButton}
                        className={`drop-shadow-md p-5 ext-center py-4 mb-12 px-10 bg-black text-white text-lg rounded-lg md:inline-block block ${this.state.showSubmitButton ? "opacity-100":"opacity-25"}`}
                        type="submit">Envoyer ma demande</button>
                    </Form>
                  </Formik>
                </div>
                <div className={`h-full w-full absolute top-0 left-0 ${this.state.isLoading ? "block":"hidden"}`}>
                  <div className="fixed top-[50%] sm:-mt-[30px] -mt-[30px] ">
                    <h2 className="font-polysans-bulky text-xl my-4">Chargement...</h2> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    )
  }
}

export default Order