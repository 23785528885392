import React from 'react'
import { Field, ErrorMessage } from 'formik';


export default function Availability(props) {

  return (
    <div>
      <div className={`my-12 ${props.lock ? "opacity-25":"opacity-100"}`}> 
        <h2 className="font-polysans-bulky text-xl my-4"><label htmlFor="availability">Mes disponibilités</label> </h2> 
        <p className="md:w-2/3 w-10/12 mb-4">Indiquez nous vos préférences (date et créneaux horaires) pour fixer une session de consultation dans les 2 prochaines semaines.</p>
        <div className="">
          <Field 
            disabled={props.lock? true : false} 
            id="availability" 
            component="textarea" 
            rows="3" 
            name="availability" 
            placeholder="Indiquer vos disponibilités... Exemple : le mardi de 17h à 18h ou mardi 14/12 de 17h à 18h" 
            className="w-96 md:w-2/3 w-10/12 p-2 font-bold rounded-lg border-2 border-purple-700 text-gray-600 bg-white hover:border-purple focus:outline-black" />
          <ErrorMessage name="availability" render={msg => 
            <div className="my-4">
              <div className="border-l-4 rounded-lg border-yellow-400 bg-yellow-50 p-4 md:w-2/3 w-10/12">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">{msg}</p>
                  </div>
                </div>
              </div>
            </div>
          } />
        </div>
      </div>
    </div>
  )
}
