import React from 'react'
import Footer from '../../Components/Footer'
import Header from '../../Components/Header'

class Payment extends React.Component {



  render() {

    return (
      <div className="font-poppins"> 
        <Header />
        <main className="bg-gray-50">
          <div className="container mx-auto my-16 md:w-2/3 w-10/12">
          
              <h3 className="pt-16 md:text-6hxl text-4hxl font-polysans-bulky">Paiement</h3>

              <form action="http://localhost:4242/create-checkout-session" method="POST">
                <button type="submit">Checkout</button>
              </form>
              
              </div>
        </main>
        <Footer />
      </div>
    )
  }
}

export default Payment
