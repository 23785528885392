import React from 'react'
import { ColorRing } from 'react-loader-spinner'



class Stepper extends React.Component {

  constructor(props) {
    super(props);
    this.currentStepClasses = "border sm:w-16 sm:h-16 w-14 h-14 border-black rounded-full text-center sm:text-2xl text-md pt-4 font-polysans-bulky mx-4 sm:my-8 my-6";
    this.defaultStepClasses = "border sm:w-16 sm:h-16 w-14 h-14 border-gray rounded-full text-center sm:text-2xl text-md pt-4 font-polysans-bulky mx-4 sm:my-8 my-6 text-gray-300";
  }

  render() {
    return (
      <div className="">

        <ul className={`fixed top-[50%] sm:-mt-[256px] -mt-[212px] sm:ml-12 ml-2 ${this.props.isLoading ? "hidden":"block"}`}>
          <li className={`${this.props.step >= 1 ? this.currentStepClasses : this.defaultStepClasses}`}>01</li>
          <li className={`${this.props.step >= 2 ? this.currentStepClasses : this.defaultStepClasses}`}>02</li>
          <li className={`${this.props.step >= 3 ? this.currentStepClasses : this.defaultStepClasses}`}>03</li>
          <li className={`${this.props.step >= 4 ? this.currentStepClasses : this.defaultStepClasses}`}>04</li>
          <li className={`${this.props.step >= 5 ? this.currentStepClasses : this.defaultStepClasses}`}>05</li>
        </ul>

        <div className="fixed top-[0px] left-0 h-full">
          <div className="bg-gray-200" style={{height:"100%", width:"4px" }}>
            <div className="bg-purple" style={{height: this.props.percent + "%", width:"4px" }}></div>
          </div>
        </div> 

        <div className={`fixed top-[50%] sm:-mt-[50px] -mt-[50px] sm:ml-12 ml-2 ${this.props.isLoading ? "block":"hidden"}`}>
          <ColorRing
            visible={true}
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper sm:w-24 sm:h-24 w-20 h-20"
            colors={['#FFE4C5', '#FF9417', '#E6DBFF', '#A880FF', '#D7FFE7', '#37F182']}
          />
        </div>

      </div>
    )
  }
}

export default Stepper