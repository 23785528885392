import React from "react";
import { Field } from 'formik';


class SpecialCode extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasSpecialCode : false }
    this.inputRef = React.createRef();
  }

  render() {
    return (
      <div className={`my-12 ${this.props.lock ? "opacity-25":"opacity-100"}`}> 
          <div className="my-2">
              <Field disabled={this.props.lock ? true : false} type="checkbox" className="accent-purple" name="has_special_code" id="hasSpecialCode" onClick={(e) => { 
                this.setState({hasSpecialCode: !this.state.hasSpecialCode }, () => {
                  this.inputRef.current.focus();
                });
              }} />
              <label className="pl-4" htmlFor="hasSpecialCode">J'ai un code client en ma possession</label>
          </div>
          <div className={`${this.state.hasSpecialCode ? "block" : "hidden"}`}>
              <Field innerRef={this.inputRef} disabled={this.props.lock ? true : false} type="text" className="w-48 p-2 font-bold rounded-lg border-2 border-purple-700 text-gray-600 bg-white hover:border-purple focus:outline-none focus:border-black" name="special_code" id="special_code" placeholder="Renseigner votre code client..." />
          </div>
      </div>
    )
  }
}

export default SpecialCode