import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Order from './Pages/Order/Order'
import SuccessOrder from './Pages/Order/Success'
import Payment from './Pages/Payment/Payment'
import SuccessPayment from './Pages/Payment/Success'
import CancelPayment from './Pages/Payment/Cancel'
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'

const router = createBrowserRouter([
  {
    path: '/',
    element: 
      <div className="fixed grid content-center w-full h-full">
        <div className="w-[512px] h-[512px] bg-purple rounded-[50px] mx-auto font-polysans-bulky text-white grid content-center text-center text-[250px]">M</div>
      </div>
  },
  {
    path: '*',
    element: 
      <div className="fixed grid content-center w-full h-full">
        <div className="w-[512px] h-[512px] bg-purple rounded-[50px] mx-auto font-polysans-bulky text-white grid content-center text-center text-[250px]">M</div>
      </div>
  },
  {
    path: '/order',
    element: <Order />
  },
  {
    path: '/order/success',
    element: <SuccessOrder />
  },
  {
    path: '/payment',
    element: <Payment />
  },
  {
    path: '/payment/success',
    element: <SuccessPayment />
  },
  {
    path: '/payment/cancel',
    element: <CancelPayment />
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
