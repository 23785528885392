import React from 'react'
import Footer from '../../Components/Footer'
import Header from '../../Components/Header'
import Countdown, { zeroPad } from 'react-countdown';

class Success extends React.Component {

  constructor(props) {
    super(props);
    /* */
    
    this.renderer = ({ days, hours, minutes, seconds, completed }) => {
      return <span className="text-lg font-bold">{zeroPad(hours + (days * 24))}h {zeroPad(minutes)}min et {zeroPad(seconds)}sec</span>;
    }

  }

  render() {

    return (
      <div className="font-poppins"> 
        <Header />
        <main className="bg-gray-50">
          <div className="container mx-auto my-16 md:w-2/3 w-10/12">
          
              <h3 className="pt-16 md:text-6hxl text-4hxl font-polysans-bulky">À vous <span className=" font-polysans-medianitalic">la réussite</span> 🤝 !</h3>

              <div className="bg-purplelight p-3 rounded-[12px] my-8">
                <div className="bg-purple rounded-[12px] p-4 text-white">
                  <strong>C'est parfait</strong>, nous avons reçu votre demande. Nous allons l'analyser et revenir vers vous dans les plus brefs délais.
                  Nous vous remercions pour votre confiance.
                </div>
              </div>

                  <h3 className="text-4hxl font-polysans-bulky mb-16"><span className=" font-polysans-medianitalic">Et</span> après ?</h3>
                  <ol className="md:flex">
                      <li className="md:w-1/4 md:grid flex place-content-between">
                      <div>
                              <p><strong className="text-2xl block font-polysans-bulky mb-4 pr-4">C'est parti !  </strong></p>
                              <p className="pr-20">Vous avez reçu un mail automatique de confirmation, merci de cliquer sur le lien du mail pour accéder à votre espace personnel. Nous vous enverrons une proposition de consultation par mail dans moins de <Countdown renderer={this.renderer} daysInHours="true" date={Date.now() + (1000 * 60 * 60 * 48)} />.</p>
                          </div>
                          <p><span className="border w-16 h-16 border-black block rounded-full text-center text-2xl pt-4 md:mt-12 mt-0 font-polysans-bulky">01</span>
                          </p>
                      </li>
                      <li className="md:w-1/4 md:grid flex place-content-between md:mt-0 mt-12">

                          <div className="">
                              <p><strong className="text-2xl block font-polysans-bulky mb-4 pr-4">Choisissez votre expert</strong></p>
                              <p className="pr-20">Entre les 2 profils proposés, et sélectionnez un créneau horaire pour la consultation.</p>
                          </div>
                          <p><span className="border w-16 h-16 border-black block rounded-full text-center text-2xl pt-4 md:mt-12 mt-0 font-polysans-bulky">02</span>
                          </p>
                      </li>
                      <li className="md:w-1/4 md:grid flex place-content-between md:mt-0 mt-12">
                          <div>
                              <p><strong className="text-2xl block font-polysans-bulky mb-4 pr-4">Réservez la consultation</strong></p>
                              <p className="pr-20">En effectuant un prépaiement par CB sur notre plateforme sécurisée. Si la consultation n’a pas lieu nous vous remboursons.</p>
                          </div>
                          <p><span className="border w-16 h-16 border-black block rounded-full text-center text-2xl pt-4 md:mt-12 mt-0 font-polysans-bulky">03</span>
                          </p>
                      </li>
                      <li className="md:w-1/4 md:grid flex place-content-between md:mt-0 mt-12">
                          <div>
                              <p><strong className="text-2xl block font-polysans-bulky mb-4 pr-4">Dialoguez avec l’expert</strong></p>
                              <p className="pr-20">Par visio conférence. Vous recevrez un mail avec le lien de connexion et un rappel dans votre calendrier.</p>
                          </div>
                          <p><span className="border w-16 h-16 border-black block rounded-full text-center text-2xl pt-4 md:mt-12 mt-0 font-polysans-bulky">04</span>
                          </p>
                      </li>
                  </ol>
              </div>
              &nbsp;
        </main>
        <Footer />
      </div>
    )
  }
}

export default Success
