import React from 'react'
import Footer from '../../Components/Footer'
import Header from '../../Components/Header'

class Cancel extends React.Component {



  render() {

    return (
      <div className="font-poppins"> 
        <Header />
        <main className="bg-gray-50">
          <div className="container mx-auto my-16 md:w-2/3 w-10/12">
          
              <h3 className="pt-16 md:text-6hxl text-4hxl font-polysans-bulky">Paiement annulé</h3>

              <div className="bg-purplelight p-3 rounded-lg my-8">
                <div className="bg-purple rounded-lg p-4 text-white">
                  <strong>C'est parfait</strong>, nous avons reçu votre demande. Nous allons l'analyser et revenir vers vous dans les plus brefs délais.
                  Nous vous remercions pour votre confiance.
                </div>
              </div>

                  <h3 className="text-4hxl font-polysans-bulky mb-16"><span className=" font-polysans-medianitalic">Et</span> après ?</h3>
                  <ol className="md:flex">
                      <li className="md:w-1/4 md:grid flex place-content-between">
                      <div>
                              <p><strong className="text-2xl block font-polysans-bulky mb-4 pr-4">C'est parti !  </strong></p>
                              <p className="pr-20">Vous recevez une proposition sur mesure dans moins de.</p>
                          </div>
                          <p><span className="border w-16 h-16 border-black block rounded-full text-center text-2xl pt-4 md:mt-12 mt-0 font-polysans-bulky">01</span>
                          </p>
                      </li>
                      <li className="md:w-1/4 md:grid flex place-content-between md:mt-0 mt-12">

                          <div className="">
                              <p><strong className="text-2xl block font-polysans-bulky mb-4 pr-4">Choisissez un talent</strong></p>
                              <p className="pr-20">Nous vous présentons une sélection de talents sur mesure. Vous choisissez un talent tout en confirmant le créneau horaire de votre visio.</p>
                          </div>
                          <p><span className="border w-16 h-16 border-black block rounded-full text-center text-2xl pt-4 md:mt-12 mt-0 font-polysans-bulky">02</span>
                          </p>
                      </li>
                      <li className="md:w-1/4 md:grid flex place-content-between md:mt-0 mt-12">
                          <div>
                              <p><strong className="text-2xl block font-polysans-bulky mb-4 pr-4">Prépaiement</strong></p>
                              <p className="pr-20">Vous prépayez la prestation. Si celle-ci n'a pas eu lieu dans les 7 jours vous êtes remboursé.</p>
                          </div>
                          <p><span className="border w-16 h-16 border-black block rounded-full text-center text-2xl pt-4 md:mt-12 mt-0 font-polysans-bulky">03</span>
                          </p>
                      </li>
                      <li className="md:w-1/4 md:grid flex place-content-between md:mt-0 mt-12">
                          <div>
                              <p><strong className="text-2xl block font-polysans-bulky mb-4 pr-4">Étape finale</strong></p>
                              <p className="pr-20">Vivez l'expérience Markopolo avec un talent en participant à une visio sur mesure.</p>
                          </div>
                          <p><span className="border w-16 h-16 border-black block rounded-full text-center text-2xl pt-4 md:mt-12 mt-0 font-polysans-bulky">04</span>
                          </p>
                      </li>
                  </ol>
              </div>
              &nbsp;
        </main>
        <Footer />
      </div>
    )
  }
}

export default Cancel
